:root {
  --diaspo-linear-gradient: linear-gradient(-45deg, #1db5ec, #dc6e15 85%);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
  width: 100vw;
  border: none;
  overflow: hidden;
  padding: none;
  margin: none;
  background-image: var(--diaspo-linear-gradient);
  background-repeat: no-repeat;
  backdrop-filter: blur(10px);
}

#body-app {
  overflow: scroll;
  height: calc(100% - 70px);
}

header#header-app {
  height: 70px;
  width: 100%;
  background-color: #fff;
  padding: 20px 30px;
  cursor: default;
}

.disabled {
  display: none;
}

header a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

#app-title {
  background-image: var(--diaspo-linear-gradient);
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-text-fill-color: transparent;
  font-size: 1.3rem;
  font-weight: 600;
}

#app-header-links {
  position: absolute;
  right: 40px;
  z-index: 999;
}

.app-header-link {
  display: inline-block;
  margin-top: 4px;
  margin-left: 1.1rem;
}

/* Modal */

#modal-component {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #00000079;
  backdrop-filter: blur(10px);
  z-index: 99999;
}

#modal-component .close-modal {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #ea0e33;
  outline: solid 4px #c2c2c2;
}

.modal-container {
  max-height: calc(100% - 60px);
}

#copy-code-component {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #616161;
  border-radius: 5px;
  box-shadow: 0 0 7px 2px #acacac78;
}

#copy-code-component span.text {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

#copy-code-component span.text span {
  display: inline-block;
  background-color: #dbdbdb;
  color: black;
  padding: 9px 15px 10px 15px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#copy-code-component span.text button {
  outline: none;
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 40px;
  padding: 10px 15px;
  font-size: 1.1rem;
  background-color: #dc6e15;
  color: white;
}

#copy-code-component hr {
  margin: 20px 0 10px 0;
  width: 100%;
  border-color: #92929276;
}

#copy-code-component p {
  width: calc(100% - 30px);
  padding: 0;
  color: white;
  margin: 12px 15px 5px 15px;
}

#save-payment-code-component {
  padding: 25px;
  background-color: #616161;
  border-radius: 5px;
  box-shadow: 0 0 7px 2px #acacac78;
}

#save-payment-code-component hr {
  margin: 20px 0 10px 0;
  width: 100%;
  border-color: #92929276;
}

#save-payment-code-component p {
  margin: 0;
  padding: 0;
  color: white;
  text-align: center;
}

/* End */

/* Login page and Sign up page */

#login-page,
#sign-up-page,
#pofile-page,
#account-activation-page,
#reset-password-page,
#not-found-page,
#change-password-page,
#change-email-page,
#init-transaction-page,
#track-transaction-page,
#user-transactions-page {
  display: flex;
  justify-content: center;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 10px 15px;
  background-color: #ffffff89;
  backdrop-filter: blur(12px);
}

#login-form,
#sign-up-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  border: solid 2px #bfbfbf72;
  border-radius: 2px;
  padding: 5px 15px;
  /* max-height: 700px; */
  overflow: auto;
}

#sign-up-form::-webkit-scrollbar {
  width: 5px;
}

#sign-up-form::-webkit-scrollbar-track {
  background-color: transparent;
}

#login-form span.input,
#sign-up-form span.input {
  display: inline-block;
  width: 100%;
}

span.input {
  position: relative;
}

span.input label {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

span.input select.input-text,
span.input input.input-text {
  display: inline-block;
  width: 100%;
  border: none;
  outline: 3px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  padding: 10px 8px;
  font-size: 1.1rem;
}

span.input input.input-text.password {
  position: relative;
  padding-right: 50px;
}

span.input input.input-text.password + span {
  position: absolute;
  right: 10px;
  margin-top: 18px;
  font-size: 0.8rem;
  font-weight: 600;
  color: #dc6e15;
  cursor: default;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  margin-top: 20px;
  position: relative;
  padding-left: 30px;
}

input[type="checkbox"] + label::after {
  position: absolute;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  border: solid gray 2px;
  top: -2px;
  left: 0;
}

input[type="checkbox"]:checked + label::after {
  background-color: #dc6e15;
}

.auth-button,
.button {
  margin-top: 30px;
  margin-bottom: 10px;
  outline: none;
  border: none;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  font-size: 1.1rem;
  background-color: #dc6e15;
  color: white;
}

span.input input.input-text.incorrect-value {
  outline-color: #bb1d1d;
}

/* End */

/* Toast */
#toast-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  padding: 0 10px;
  bottom: 0;
  left: 0;
  z-index: 9999999;
}

.toast-item {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: none;
  background-color: #fff;
  outline: solid 2px;
  margin: 5px 0;
  padding: 3px 10px;
  overflow: hidden;
}

.toast-item.error {
  outline-color: #bb1d1d;
}

.toast-item.alert {
  outline-color: #dc6e15;
}

.toast-item.success {
  outline-color: #19ca49;
}

.toast-item.info {
  outline-color: #e8d212;
}

.toast-item .toast-title {
  width: 80%;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 1.1rem;
}

.toast-item .toast-message {
  width: 100%;
  display: inline-block;
  font-size: 0.9rem;
}

.toast-item .toast-message p {
  font-size: inherit;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}

.toast-item .close-toast {
  border-radius: 50%;
  height: 15px;
  width: 15px;
  padding-top: 0;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #f60c61d6;
  color: white;
}
/* End */

/* User profile page */
#profile-banner {
  width: 100%;
  margin-bottom: 15px;
  background-color: #ffffff99;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 10px;
  overflow: hidden;
}

#profile-banner #cover {
  width: 100%;
  height: 200px;
}

#profile-banner #cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

#profile-banner #avatar {
  display: block;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  outline: #dc6e15 solid 4px;
  margin: -70px 0 15px 0;
  background-color: #ffffff;
}

#profile-banner #user-full-name {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
}

.user-info-item {
  width: 100%;
  background-color: #ffffffa9;
  border-radius: 7px;
  margin: 3px 0;
  display: inline-block;
  padding: 7px 10px;
  height: auto;
}

.info-property {
  display: inline-block;
  width: 100%;
  margin: 3px 0;
  font-weight: 600;
  font-size: 0.8rem;
}

.info-value {
  display: inline-block;
  width: 100%;
  margin: 3px 0;
  font-size: 1.1rem;
}
/* End */

/* Transaction item */

.transaction-item {
  cursor: pointer;
  background-color: #dcdcdcb0;
  margin: 5px 0;
  border-radius: 4px;
  padding: 10px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s ease;
}

.transaction-item:active,
.transaction-item:focus,
.transaction-item:hover {
  background-color: #e9e9e9b0;
  outline: solid 2px #e9e9e9;
}

.transaction-item .info * {
  display: inline-block;
  width: 100%;
  text-align: left;
}

.transaction-item .info label {
  font-size: 0.8rem;
  margin-bottom: 3px;
}

/* End */

@media screen and (min-width: 721px) {
  /* Login page and Sign up page */
  #login-form,
  #sign-up-form,
  #init-transaction-form,
  #login-text,
  #sign-up-text,
  #init-transaction-text {
    width: 50%;
  }
  /* End */

  .modal-container {
    width: 500px;
  }

  /* Toast */
  #toast-container {
    width: 450px;
  }
  /* End */

  /* Transaction item */

  .transaction-item .info {
    width: 33%;
  }

  /* End */

#copy-code-component span.text {
  width: 80%;
  padding: 10px;
}
}

@media screen and (max-width: 721px) {
  #menu-toggle,
  #menu-toggle::before,
  #menu-toggle::after {
    position: absolute;
    display: inline-block;
    height: 0;
    width: 16px;
    border: solid 1px black;
  }

  #menu-toggle {
    top: 35px;
    right: 40px;
    width: 18px;
  }

  #menu-toggle::before,
  #menu-toggle::after {
    content: "";
    left: -1px;
    padding: 0;
    margin: 0;
  }

  #menu-toggle::before {
    top: -7px;
  }

  #menu-toggle::after {
    top: 5px;
  }

  #app-header-links {
    position: absolute;
    display: inline-block;
    top: 70px;
    left: 0;
    width: 100%;
    height: auto;
    overflow-y: hidden;
    justify-content: space-between;
    background-color: #ffffff96;
    backdrop-filter: blur(10px);
    transition: height 0.3s;
  }

  #app-header-links.list-disabled {
    height: 0;
  }

  .app-header-link {
    display: inline-flex;
    height: 30px;
    width: 100%;
    margin: 5px 0;
    justify-content: center;
    align-items: center;
  }

  /* Login page and Sign up page */
  #login-page,
  #sign-up-page,
  #init-transaction-page {
    flex-direction: column;
  }

  #login-form,
  #sign-up-form,
  #init-transaction-form,
  #login-text,
  #sign-up-text,
  #init-transaction-text {
    width: 100%;
  }
  /* End */

  .modal-container {
    width: 100%;
  }

  /* Toast */
  #toast-container {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  /* End */

  /* Transaction item */

  .transaction-item {
    flex-direction: column;
  }

  .transaction-item .info {
    width: 100%;
  }

  /* End */
}
